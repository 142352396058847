import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Select,
  Collapse,
  Steps,
  Input,
  Modal,
  Divider,
} from "antd";
import React, { useContext, useEffect, useState } from "react";

import {
  PhoneNumberField,
  SelectInput,
  TextInputBox,
} from "../../components/InputField";

import apiPath from "../../constants/apiPath";
import { AppStateContext, useAppContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";

import { useNavigate, useParams } from "react-router";
import EditIcon from "../../assets/images/edit.svg";
import deleteWhiteIcon from "../../assets/images/icon/deleteWhiteIcon.png";
import Loader from "../../components/Loader";
import { EditOutlined } from "@ant-design/icons";
import EditMobile_Mail from "../../components/EditMobile_Mail";
import OtpModal from "../../components/OtpModal";
import DeleteModal from "../../components/DeleteModal";
import { AuthContext } from "../../context/AuthContext";

const PersonalDetails = () => {
  const api = {
    addEdit: apiPath.updateProvider,
    fetch: apiPath.getProvider,
    country: apiPath.common.countries,
    city: apiPath.common.city,
  };
  const { Step } = Steps;
  const { Panel } = Collapse;
  const sectionName = lang("Profile");
  const routeName = "Profile";
  const heading = sectionName + " " + lang("Management");
  const { setPageHeading } = useContext(AppStateContext);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [data, setData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [cities, setCities] = useState([]);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [edit, setEdit] = useState();
  const [updatedFormData, setUpdatedFormData] = useState();
  const {language} = useAppContext()
  const { userProfile , setRefreshUserProfile } = useContext(AuthContext);




  useEffect(() => {
    setPageHeading(heading);
  }, []);

  const getCountry = () => {
    setLoading(true)
    request({
      url: apiPath.common.country,
      method: "GET",
      onSuccess: ({ data, status }) => {
    setLoading(false)
        if (status) {
          setCountries(data.data);
        }
      },
    });
  };

  const getCity = (id) => {
    request({
      url: apiPath.common.city + "/" + id,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setCities(data?.data);
        }
      },
    });
  };

  const getProvider = () => {
    setLoading(true);
    request({
      url: api.fetch,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setLoading(false);

        if (status) {
          setData(data.data);
        }
        console.log(data, "lll");
      },
      onError: (err) => {
        setLoading(false);

        console.log(err);
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    getCountry();
    getProvider();
  }, [refresh]);

  useEffect(() => {
    if (!data) return;

    console.log(data, "vendorEdit");
    form.setFieldsValue({
      ...data,
      mobile : data?.country_code && data?.mobile_number ? data?.country_code + data?.mobile_number  : "",
      country_id: data?.country_id,
      city_id: data?.city_id,
      location_on_map: data?.address,
    });

    setMobileNumber({
      country_code: data?.country_code,
      mobile_number: data?.mobile_number,
    });

    getCountry();
    if (data?.country_id) getCity(data?.country_id);
  }, [data]);

  const onCreate = (values) => {
    setLoading(true);

    let payload = {
      ...mobileNumber,
      ...values,
    };

    console.log(payload, "payload");

    request({
      url: api.addEdit,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          setIsEdit(false);
          getProvider();
          setRefreshUserProfile(prev => !prev)
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const handleMobileNumberChange = (value, data, type) => {
    let country_code = data?.dialCode;
    setMobileNumber({
      country_code: country_code,
      mobile_number: value.slice(data?.dialCode?.length),
    });
  };

  return (
    <>
      <Col span={24} lg={24} xs={24}>
        <div className="row g-3">
          <div className="col-12  d-flex align-items-center gap-3 justify-content-end">
            <Button
              disabled={!data}
              onClick={() => setIsEdit(true)}
              className="edit-cls btnStyle primary_btn  py-2 px-3  d-flex align-items-center gap-1"
            >
              <img src={EditIcon} />
              {lang("Edit")}
            </Button>
          </div>
        </div>
      </Col>

      <Form
        id="create"
        form={form}
        onFinish={onCreate}
        layout="vertical"
        disabled={data ? !isEdit : false}
      >
        <Row gutter={[16, 16]}>
          <Col span={24} lg={24} xs={24} className="d-flex flex-column gap-3">
            <Card className="main-newheader-ofall" title={lang("Personal Details")}>
              {loading ? (
                <Loader />
              ) : (
                <Row gutter={[24, 24]}>
                  <Col span={24} md={12}>
                    <TextInputBox
                      name="contact_person_name"
                      label={lang("Contact Person Name")}
                      placeholder={lang("Enter Contact Person Name")}
                      cover={{ md: 24 }}
                      colProps={{ sm: 24, span: 24 }}
                      rules={[
                        {
                          max: 200,
                          message: lang(
                            "Name should contain more then 200 characters!"
                          ),
                        },
                        {
                          required: true,
                          message: lang("Please Enter the name"),
                        },
                      ]}
                    />
                  </Col>
                  <Col span={24} md={12}>
                    <Form.Item
                      label={
                        <div className="edit-main-email-09">
                        <h3>{lang("Email ID") }</h3>
                          <h4>
                            {!isEdit ? (
                              <span onClick={() => setEdit("email")}>
                                <EditOutlined /> {lang("Edit")}
                              </span>
                            ) : (
                              ""
                            )}
                          </h4>
                        </div>
                      }
                      name="email"
                      cover={{ md: 24 }}
                      colProps={{ sm: 24, span: 24 }}
                      rules={[
                        {
                          type: "email",
                          message: lang("Please enter a valid email address!"),
                        },
                        {
                          max: 50,
                          message: lang(
                            "Email address not more then 255 characters!"
                          ),
                        },
                        {
                          required: true,
                          message: lang("Please Enter the email"),
                        },
                      ]}
                    >
                      <Input
                        disabled={true}
                        placeholder={lang("Enter Email ID")}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} md={12}>
                  
                    <PhoneNumberField
                      disabled={true}
                      rules={true}
                      label={
                   <div className="edit-main-email-09"><h3>{lang("Contact Person Phone Number")}</h3>
                    <h4>{!isEdit ? ( <span onClick={() => setEdit("number")}><EditOutlined /> {lang("Edit")} </span> ) : ("" )}</h4>
                    </div>


                      }
                      name="mobile"
                      placeholder={lang("Enter Phone Number")}
                      cover={{ md: 24 }}
                      colProps={{ sm: 24, span: 24 }}
                      className=""
                      onChange={handleMobileNumberChange}
                      number={mobileNumber?.mobile_number}

                    />
                  </Col>

                  <Col span={24} md={12}>
                    <Form.Item
                      label={lang("Country")}
                      name="country_id"
                      rules={[
                        {
                          required: true,
                          message: lang("Please select the country!"),
                        },
                      ]}
                    >
                      <Select
                        filterOption={(input, option) =>
                          option?.label
                            ?.toLowerCase()
                            .indexOf(input?.toLowerCase()) >= 0
                        }
                        autoComplete="off"
                        placeholder={"Select Country"}
                        showSearch
                        onChange={(value) => getCity(value)}
                      >
                        {countries.map((item) => (
                          <Select.Option
                            key={item._id}
                            label={item.name}
                            value={item._id}
                          >
                           
                            { language !== "en" && language !== null
                            ? item?.[`${language}_name`] ?? item?.name
                            : item?.name }
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24} md={12}>
                    <SelectInput
                      name="city_id"
                      label={lang("City")}
                      placeholder={lang("Select City")}
                      showSearch
                      filterOption={(input, option) =>
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      options={cities}
                      cover={{ md: 24 }}
                      colProps={{ sm: 24, span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: lang("Please Enter City"),
                        },
                      ]}
                    />
                  </Col>
                </Row>
              )}

              {isEdit ? (
                <div className="view-provider">
                  <Button form="create" type="primary" htmlType="submit">
                    {lang("Save")}
                  </Button>
                </div>
              ) : (
                ""
              )}
            </Card>
          </Col>
        </Row>
      </Form>
    

      {(edit === "email" || edit === "number") && (
        <EditMobile_Mail
          onUpdate={(data) => setUpdatedFormData(data)}
          edit={edit}
          onHide={(type) => setEdit(type)}
        />
      )}
      {edit === "otp" && (
        <OtpModal
          formData={updatedFormData}
          onClose={(type) => setEdit(type)}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
    </>
  );
};

export default PersonalDetails;
