import React, { useEffect, useState } from "react";

import {
  Avatar,
  List,
  Skeleton,
  Col,
  Modal,
  Form,
  Row,
  Radio,
  Button,
} from "antd";
import moment from "moment";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import InfiniteScroll from "react-infinite-scroll-component";
import lang from "../../helper/langHelper";
import Prouser from "../../assets/images/user.png";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { Severty, ShowToast } from "../../helper/toast";
import DeleteModal from "../../components/DeleteModal";
import { useNavigate } from "react-router";
import Header from "../../components/layout/Header";
import { useAppContext } from "../../context/AppContext";

export const NotificationList = () => {
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [list, setList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [show, setShow] = useState(false);
  const [readShow, setReadShow] = useState(false);
  const {language,setRefreshNotification} = useAppContext()
  const [selected, setSelected] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    total: 10,
  });
  const [showAllDelete, setShowAllDelete] = useState(false);

  const navigate = useNavigate();

  const { request } = useRequest();

  const dates = {
    today: moment(new Date()).format("YYYY-MM-DD"),
    yesterday: moment(new Date().getTime() - 24 * 60 * 60 * 1000).format(
      "YYYY-MM-DD"
    ),
  };

  const fetchData = () => {
    request({
      url:
        apiPath.notificationList +
        `?status=${""}&page=${pagination ? pagination.current : 1}&pageSize=${
          pagination && pagination?.total ? pagination?.total : 10
        }&search=${""}`,
      method: "GET",
      onSuccess: ({ data }) => {
        setList(data?.data);
        data?.data?.length == 0 && setHasMore(false);
        setLoading(false);
        setTimeout(() => {        
          setRefreshNotification(true)
        }, 20);
      },
      onError: (err) => {
        console.log(err);
        setLoading(false);
      },
    });
  };

  const deleteNotification = (id) => {
    setLoading(true);
    request({
      url: `${apiPath.notification}/delete/${selected}`,
      method: "DELETE",
      onSuccess: ({ data, message }) => {
        ShowToast(message, Severty.SUCCESS);
        setRefresh((prev) => !prev);
        setLoading(false);
        setSelected("");
        setTimeout(() => {        
          setRefreshNotification(true)
        }, 20);
      },
      onError: (err) => {
        ShowToast(err?.response?.data?.message, Severty.ERROR);
        console.log(err);
        setLoading(false);
      },
    });
  };

  const onNext = ({}) => {
    console.log("Call huaa next");
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [refresh, pagination]);

  const handleNavigate = (noti) => {
    console.log(noti, "useFor...");

    if (noti.use_for === "QUOTE") {
      navigate("/quote");
    } else if (noti.use_for === "REPORT") {
      navigate("/reports");
    } else if (noti.use_for === "RATE") {
      navigate("/ratings");
    } else if (noti.use_for === "TERMS_CONDITION") {
      navigate("/content");
    }

    if (noti.use_for && !noti?.is_read) {
      request({
        url: `${apiPath.notification}/read/${noti?._id}`,
        method: "POST",
        onSuccess: ({ data }) => {
          ShowToast("Notification Read Successfully", Severty.SUCCESS);
          setRefresh((prev) => !prev);
          setTimeout(() => {        
            setRefreshNotification(true)
          }, 20);
        },
        onError: (err) => {
          console.log(err);
        },
      });
    }
  };

  const readAllNotifcation = () => {
    request({
      url: `${apiPath.notification}/read`,
      method: "POST",
      onSuccess: ({ data }) => {
        ShowToast("Notification Read All Successfully", Severty.SUCCESS);
        setRefresh((prev) => !prev);
        setTimeout(() => {        
          setRefreshNotification(true)
        }, 20);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  return (
    <>
      {loading ? (
        <Skeleton />
      ) : (
        <>
          <InfiniteScroll
            dataLength={list.length} //This is important field to render the next data
            next={onNext}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
            endMessage={
              <p style={{ textAlign: "center" }}>
                {list.length == 0 ? (
                  <b>{lang("No notification")}</b>
                ) : (
                  <b>{lang("Yay! You have seen it all")}</b>
                )}
              </p>
            }
          >
            <div className="main-dltall-not-all">
              <Button
                className="deleteall-main-button"
                disabled={!list?.length}
                onClick={() => {
                  setShowAllDelete(true);
                }}
              >
                {lang("Delete All")}
              </Button>
              <Button
                className="deleteall-main-button"
                disabled={!list?.length}
                onClick={() => {
                  readAllNotifcation();
                }}
              >
                {lang("Read All")}
              </Button>
            </div>
            {list?.map((data, index) => {
              console.log(data, "datadatadata");
              return (
                <div className="notification-card">
                  <h4>
                    {moment(data._id).format("YYYY-MM-DD") === dates.today
                      ? lang("Today's Notifications")
                      : moment(data._id).format("YYYY-MM-DD") ===
                        dates.yesterday
                      ? lang("Yesterday's Notifications")
                      : moment(data._id).format("YYYY-MM-DD")}
                  </h4>
                  <List
                    key={"groupItem" + index}
                    itemLayout="horizontal"
                    className="main-render-notifi-456"
                    dataSource={data.notifications}
                    renderItem={(item, index) => (
                      <List.Item
                        className={`remamber-main ${
                          item?.is_read
                            ? "Notification-read"
                            : "Notification-unread"
                        }`}
                      >
                        <List.Item.Meta
                          onClick={() => handleNavigate(item)}
                          avatar={
                            <Avatar src={item?.from_id?.image ?? Prouser} />
                          }
                          title={
                            <div className="main-time-for998">
                              <div>  { language !== "en" && language !== null
                            ? item?.[`${language}_title`] ?? item?.title
                            : item?.title }</div>

                              <h6>
                                {moment(item?.created_at).format("hh:mm a")}
                              </h6>
                            </div>
                          }
                          description={
                            <div className="notifivcation-description-main88">
                              <div
                                style={{ display: "inline" }}
                                className="main-notification-itemcontent"
                              >
                               
                                { language !== "en" && language !== null
                            ? item?.[`${language}_description`] ?? item?.description
                            : item?.description }
                              </div>
                              <div className="main-delete-out-main8jh">
                                <div
                                  className="main-delete-out"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setSelected(item?._id);
                                    setShow(true);
                                  }}
                                >
                                  <DeleteOutlined />
                                </div>
                              </div>
                            </div>
                          }
                        />
                      </List.Item>
                    )}
                  />
                </div>
              );
            })}
          </InfiniteScroll>
          {show && (
            <DeleteModal
              title={lang("Delete Notification")}
              subtitle={lang(`Are you sure you want to delete this notification?`)}
              show={show}
              hide={() => {
                setShow(false);
                setSelected("");
              }}
              onOk={(value) => deleteNotification(value)}
            />
          )}
          {readShow && (
            <ReadNotification
              title={lang("Notification")}
              show={readShow}
              hide={() => {
                setReadShow(false);
                setSelected("");
              }}
              data={selected}
            />
          )}
        </>
      )}
      {showAllDelete && (
        <DeleteModal
          title={lang("Delete All Notification")}
          subtitle={lang(`Are you sure you want to delete all notification?`)}
          show={showAllDelete}
          hide={() => {
            setShowAllDelete(false);
            setSelected("");
          }}
          onOk={(value) => {
            setSelected();
            deleteNotification();
          }}
        />
      )}
    </>
  );
};

const ReadNotification = ({ show, hide, data, title }) => {
  const [value, setValue] = useState();
  const { request } = useRequest();
  const {language} = useAppContext()


  useEffect(() => {
    if (!data?.is_read) {
      request({
        url: `${apiPath.notification}/read/${data?._id}`,
        method: "POST",
        onSuccess: ({ data }) => {
          console.log("read successfully", data);
        },
        onError: (err) => {
          console.log(err);
        },
      });
    }
  }, [data]);

  return (
    <Modal
      width={700}
      open={show}
      onCancel={hide}
      centered
      className="tab_modal deleteWarningModal"
      footer={false}
    >
      <Form layout="vertical" className="p-2">
        <h4 className="modal_title_cls mb-2">{title}</h4>
        {data ? (
          <Row gutter={[16, 16]} className="justify-content-center">
            <Col md={16}>
              <div className="d-block">
                <b>  { language !== "en" && language !== null
                            ? data?.[`${language}_title`] ?? data?.title
                            : data?.title }</b>
              </div>
              <div>    
              { language !== "en" && language !== null
                            ? data?.[`${language}_description`] ?? data?.description
                            : data?.description }</div>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </Form>
    </Modal>
  );
};
