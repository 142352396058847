import { Button, Col, Image, Card, Table, Tabs, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { dateString } from "../../helper/functions";
import lang from "../../helper/langHelper";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import { useNavigate, useParams } from "react-router";
import Loader from "../../components/Loader";
import { AppStateContext } from "../../context/AppContext";


const ViewReport = ({}) => {
  const { request } = useRequest();
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);
  const { setPageHeading } = useContext(AppStateContext);
  const navigate = useNavigate();

  const params = useParams();
 

  const getData = (id) => {
    setLoading(true);
    request({
      url: `${apiPath.reportList}/view/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setLoading(false);
        if (!status) return;
        setData(data);
   
      },
      onError: (err) => {
        setLoading(false);
        console.log(err, " : error : ");
      },
    });
  };

  useEffect(() => {
    if (!params.id) return;
    setLoading(true);
    getData(params.id);
  }, [params.id]);

  useEffect(() => {
    setPageHeading(lang("Report  Management"));
  }, []);

  return (
    <>
      <div className="tab_inner_tit">
        <div className="tab-upload-wrap d-flex align-items-center justify-content-between"></div>
      </div>

      <div className="table-responsive customPagination withOutSearilNo">
        {loading ? (
          <Loader />
        ) : (
          <Card className="quote-card" title={lang("View Details")}>
            <div className="label-quote-card">
              {lang("Customer Name")} : <span className="view-quote-text">{data?.user_id ? data?.user_id?.name : ""}</span>
            </div>
            
         
            <div className="label-quote-card">
              {" "}
              {lang("Description")} : <span className="view-quote-text">{data?.comment ? data?.comment : ""}</span> 
            </div>
           
          </Card>
        )}
      </div>
     
    </>
  );
};

export default ViewReport;
