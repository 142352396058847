import { useContext, useEffect, useState } from 'react';
import useRequest from "../hooks/useRequest";
import {Col, Modal, Form ,Row,Input} from "antd"
import lang from '../helper/langHelper';
import apiPath from '../constants/apiPath';
import PhoneInput from 'react-phone-input-2';
import { AuthContext } from '../context/AuthContext';
import { Severty, ShowToast } from '../helper/toast';


const EditMobile_Mail = ({ edit, onHide, onUpdate }) => {

    const { userProfile } = useContext(AuthContext);
    const [email, setEmail] = useState(userProfile?.email);
    const [mobileNumber, setMobileNumber] = useState({
        country_code  : userProfile?.country_code || '',
        mobile_number : userProfile?.mobile_number || '',
    });
    const [form] = Form.useForm()
    const [error, setError] = useState();
    const { request } = useRequest();
  

    const isValidEmail = (email) => {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const handleSubmit = (values) => {
     
        if (edit === 'email') {
            if ((!values?.email) || !isValidEmail(values?.email)) {
                ShowToast(lang("Please enter valid email"), Severty.ERROR)
            }
            else {
                let body = {
                    email: values?.email,
                    type: userProfile?.type,
                    verify: 'email',
                }
                onUpdate(body);
                request({
                    url: apiPath.sendUpdateOtp,
                    method: 'POST',
                    data: body,
                    onSuccess: (data) => {
                        if (data.status) {
                            ShowToast(data.message, Severty.SUCCESS);
                            onHide('otp')
                        }
                        else{
                            ShowToast(data.message, Severty.ERROR);
                        }
                    },
                    onError: (err) => {
                        console.log(err);
                        onHide('')
                        ShowToast(err.message, Severty.ERROR)
                    }

                })
            }
        } else {
            if (!mobileNumber.mobile_number) {
               
                ShowToast("Please enter mobile number" , Severty.ERROR)
            } else if (mobileNumber.mobile_number.length < 7) {
                ShowToast("Please enter minimum 7 digit" , Severty.ERROR)
            }
            else {
                let body = {
                    country_code : mobileNumber.country_code,
                    mobile_number : mobileNumber.mobile_number,
                    type : userProfile?.type,
                    verify: 'mobile',
                }
                onUpdate(body);
                request({
                    url: apiPath.sendUpdateOtp,
                    method: 'POST',
                    data: body,
                    onSuccess: (data) => {
                        if (data.status) {
                            ShowToast(data.message, Severty.SUCCESS);
                            onHide('otp');
                        }
                        else{
                            ShowToast(data.message, Severty.ERROR);
                            onHide('');
                        }
                    },
                    onError: (err) => {
                        console.log(err);
                        onHide('');
                        ShowToast(err.message, Severty.ERROR)
                    }

                })
            }
        }

    }

    useEffect(() => {
      if(!userProfile) return;


        form.setFieldsValue({
          email : userProfile?.email,
          mobile : userProfile.country_code + userProfile.mobile_number
        })

        setMobileNumber({
          country_code: userProfile.country_code,
          mobile_number: userProfile.mobile_number,
        });

    }, [userProfile]);

    const handleChange = (value, data) => {
        var country_code = data.dialCode;
        setMobileNumber({
          country_code: country_code,
          mobile_number: value.slice(data.dialCode.length),
        });
      };

      

    return (
        <>
        <Modal
        open={true}
        onCancel={() => onHide('')}
       
        width={749}
        className="noevent-model"
        okText="Submit"
        cancelText="Back"
        okButtonProps={{
          form: "update-data",
          htmlType: "submit",
          className: "submit-button",
        }}
        cancelButtonProps={{
          className: "back-button", 
        }}
      >
        <div className="no-active_event">
          <div className="event-backgg-header setEdit-main-78">
            <h2>{edit === 'email' ? 'Edit Email' : 'Edit Number'}</h2>
          </div>
          <div className="noevent-text">
            <Form
              className="noevent-maain-model"
              id="update-data"
              form={form}
              onFinish={handleSubmit}
              layout="vertical"
            >
              <Row gutter={[24, 0]}>
              {edit === 'email'
              ?  <Col span={24}>
            <Form.Item
              label={lang("Email ID")}
              name="email"
              rules={[
                {
                  type: "email",
                  message: lang("The email is not a valid email!"),
                },
                { required: true, message: lang("Please enter the email!") },
                {
                  max: 200,
                  message: lang(
                    "Email should not contain more then 200 characters!"
                  ),
                },
                {
                  min: 5,
                  message: lang("Email should contain at least 5 characters!"),
                },
                {
                  pattern: new RegExp(
                    /^([a-zA-Z0-9._%-]*[a-zA-Z]+[a-zA-Z0-9._%-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/
                  ),
                  message: lang("Enter valid email!"),
                },
              ]}
            >
              <Input
                autoComplete="off"
                placeholder={lang("Enter Email Address")}
              />
            </Form.Item>
          </Col>
               
               :  <Col span={24} className="flagMobileNumber">
               <Form.Item
                 label={lang("Mobile Number")}
                 name="mobile"
                 rules={[
                   {
                     required: true,
                     validator: (rule, value) => {
                       if (!value) {
                         return Promise.reject(lang("Please enter phone number"));
                       }
                       if (!/^\d{11,16}$/.test(value)) {
                         return Promise.reject(
                           lang("Phone number must be between 8 and 12 digits"),
                         );
                       }
                       return Promise.resolve();
                     },
                   },
                 ]}
               >
                 <PhoneInput
                   inputProps={{
                     name: "mobile",
                     required: true,
                     autoFocus: false,
                     autoFormat: false,
                     autoComplete: "off",
                   }}
                   isValid={(value, country) => {
                     if (value.match(/1234/)) {
                       return "Invalid value: " + value + ", " + country.name;
                     } else if (value.match(/1234/)) {
                       return "Invalid value: " + value + ", " + country.name;
                     } else {
                       return true;
                     }
                   }}
                   country={"jo"}
                   preferredCountries={["jo"]}
                   onChange={handleChange}
                 />
               </Form.Item>
   
             </Col> }
            
              </Row>
            </Form>
          </div>
        </div>
      </Modal>

        
        </>
        )
}

export default EditMobile_Mail;