import React, { useState, useEffect, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import apiPath from "../constants/apiPath";
import { AuthContext } from '../context/AuthContext';
import useRequest from '../hooks/useRequest'
import { Severty, ShowToast } from '../helper/toast';
import lang from '../helper/langHelper';
import { Spinner } from 'react-bootstrap';
import { OTPMessage } from '../constants/conts';

const OtpModal = ({ onClose, formData, refresh }) => {
    const { request } = useRequest();
    const { setIsLoggedIn, setUserProfile, userProfile , setRefreshUserProfile } = useContext(AuthContext)
    const [otp, setOTP] = useState(['', '', '', '']);
    const [resendTimer, setResendTimer] = useState(60);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);

    useEffect(() => {
        let timer;
        if (resendTimer > 0) {
            timer = setTimeout(() => {
                setResendTimer(resendTimer - 1);
            }, 1000);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [resendTimer]);

    const handleInputChange = (e, index) => {
        const value = e.target.value;

        if (!isNaN(value) && value.length <= 1) {
            // Ensure the input is numeric and one character or less
            const updatedOTP = [...otp];
            updatedOTP[index] = value;

            // Move focus to the next input if there's a value
            if (value && index < otp.length - 1) {
                document.getElementById(`otp-input-${index + 1}`).focus();
            }
            setOTP(updatedOTP);
        }
    };

    const handleKeyDown = (e, index) => {
        const updatedOTP = [...otp];
        if (e.key === 'Backspace' && index > 0) {
            // Move focus to the previous input when Backspace is pressed
            const updatedOTP = [...otp];
            if (updatedOTP[index] !== '') {
                updatedOTP[index] = '';
            } else {
                document.getElementById(`otp-input-${index - 1}`).focus();
            }
            setOTP(updatedOTP);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
            const body = {
                ...formData,
                otp: otp.join("")
            }
            setLoading(true);
            request({
                url: apiPath.verifyUpdateOtp,
                method: "POST",
                data: body,
                onSuccess: (data) => {
                    if (data.status) {
                        ShowToast(data.message, Severty.SUCCESS);
                        setUserProfile(data.data);
                        localStorage.setItem('userProfile', JSON.stringify(data.data));
                        setRefreshUserProfile(prev => !prev)
                        onClose("");
                        refresh()
                    }
                    else {
                        ShowToast(data.message, Severty.ERROR);
                        console.log("Something went wrong", data);
                    }
                    setLoading(false);
                },
                onError: (err) => {
                    onClose("");
                    console.log(err);
                    setLoading(false);
                }
            })
        
        setOTP(['', '', '', '']);
        document.getElementById(`otp-input-0`).focus();
    }

    const handleResend = (e) => {
        if (resendTimer > 0) return
        setResendTimer(60);     
            setLoading2(true);
            request({
                url: apiPath.sendUpdateOtp,
                method: "POST",
                data: formData,
                onSuccess: (data) => {
                    if (data.status) {
                        ShowToast(data.message, Severty.SUCCESS);
                    }
                    else {
                        ShowToast(data.message, Severty.ERROR);
                    }
                    setLoading2(false);
                },
                onError: (err) => {
                    console.log(err);
                    setLoading2(false);
                    ShowToast(err.message, Severty.ERROR);
                }
            })
    
        setOTP(['', '', '', '']);
    }




    return <>
        <Modal className="otpModal noevent-model" show={true} onHide={() => onClose()} centered backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title className="fs-5 fw-bold">{lang("OTP Verification")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="loginSignUpForm" onSubmit={handleSubmit}>
                    <div className='text-center'>
                        {formData?.verify === "email" ?
                         <span className='' style={{ fontWeight: "500" }}>Enter OTP that has been sent to your Email. : <p style={{ display: 'block' }}>{formData?.email}</p></span>
                      :  <span className='' style={{ fontWeight: "500" }}>{"Enter OTP that has been sent to your mobile number."} : <p style={{ display: formData?.country_code ? 'block' : 'none' }}>+{formData?.country_code}-{formData?.mobile_number}</p></span>}
                    </div>
                    <Row className="gap-3">
                        <Form.Group as={Col} md="12" className="d-flex align-items-center justify-content-center gap-4 my-3">
                            {otp.map((digit, index) => (
                                <Form.Control
                                    key={index}
                                    type="text"
                                    value={digit}
                                    onChange={(e) => handleInputChange(e, index)}
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                    maxLength={1}
                                    id={`otp-input-${index}`}
                                    className="text-center p-0 valid"
                                />
                            ))}
                        </Form.Group>
                        <Form.Group as={Col} md="12" className="mt-3">
                            <Button type="submit" className="btn btnStyle1 shadow-none text-white px-4 w-100 primary_btn btnStyle add-item-btn">{loading ? <Spinner animation="border" size="sm" /> : lang("Verify OTP")}</Button>
                        </Form.Group>
                        <Form.Group as={Col} md="12" className="mb-2">
                            <div className="otherLink text-center ">{lang("Didn't Receive OTP")}?
                                <button disabled={resendTimer} style={{ background: "none", border: "none", color: resendTimer ? "GrayText" : "#0AA9DD" }} onClick={handleResend} className="textBtn">{loading2 ? <Spinner animation="border" size="sm" /> : lang("Resend")} </button>
                                <span style={{ float: "right" }}>{resendTimer ? (resendTimer <= 9 ? "0" + resendTimer + "sec" : resendTimer + "sec") : ("")}</span>
                            </div>
                        </Form.Group>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    </>
}

export default OtpModal;