import React, { Suspense, useContext } from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { AppContextProvider } from "./context/AppContext";
import { AuthContext, AuthProvider } from "./context/AuthContext";

import { I18nextProvider } from "react-i18next";
import i18n from "./config/i18n";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/custom.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import Loader from "./components/Loader";
import PrivateRoute from "./components/PrivateRoute";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./pages/Auth/Home";
import SignIn from "./pages/Auth/SignIn";
import Error from "./pages/Error";
import Terms from "./pages/Auth/TermsAndCondition"




import {
  Register,
  Notifications,
  Ratings,
  Reports,
  QuotationRequest,
  PersonalDetails,
  Gallary,
  BussinessDetails,
  Services,
  Packages,
  Chat,
  Contents
} from "./pages";
import ViewRequested from "./pages/QuotationRequests.js/View";
import ViewReport from "./pages/Reports/View";


window.Buffer = window.Buffer || require("buffer").Buffer;
function App() {
  return (
    <AuthProvider>
      <AppContextProvider>
        <I18nextProvider i18n={i18n}>
          <Suspense fallback={<Loader />}>
            <BrowserRouter>
              <ScrollToTop />
              <ToastContainer closeOnClick={false} />
              <AppRoutes />
            </BrowserRouter>
          </Suspense>
        </I18nextProvider>
      </AppContextProvider>
    </AuthProvider>
  );
}

const AppRoutes = () => {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <Routes>
      <Route path="/login" element={<SignIn />} />
      <Route path="/sign-up" element={<Register />} />
      <Route path="/terms-and-conditions" element={<Terms />} />

      <Route
        path="/"
        element={
          <PrivateRoute>
            <Layout />
          </PrivateRoute>
        }
      >
        {/* Auth Routes */}
        <Route exact path="/" element={<Home />} />
        <Route exact path="/dashboard" element={<Home />} />

       
        <Route exact path="/personal-details" element={<PersonalDetails />} />
        <Route exact path="/bussiness-details" element={< BussinessDetails/>} />
        <Route exact path="/gallary" element={<Gallary />} />
        <Route exact path="/package" element={<Packages />} />
        <Route exact path="/quote" element={<QuotationRequest />} />
        <Route exact path="/quote/:id" element={<ViewRequested />} />
        <Route exact path="/ratings" element={<Ratings />} />
        <Route exact path="/notification" element={<Notifications />} />
        <Route exact path="/reports" element={<Reports />} />
        <Route exact path="/reports/:id" element={<ViewReport />} />
        <Route exact path="/service" element={<Services />} />
        <Route exact path="/chat" element={<Chat />} />
        <Route exact path="/content" element={<Contents />} />

   





    


      </Route>

      <Route path="*" element={<Error />} />
    </Routes>
  );
};

const Layout = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default App;
