import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Layout,
  Row,
  Select,
  Space,
  Switch,
  TimePicker,
} from "antd";
import * as _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import signinLogo from "../../assets/images/Logo.svg";

import {
  PhoneNumberField,
  SelectInput,
  TextInputBox,
} from "../../components/InputField";
import SingleImageUpload from "../../components/SingleImageUpload";
import apiPath from "../../constants/apiPath";
import { AuthContext } from "../../context/AuthContext";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import Otp from "./Otp";

import lang from "../../helper/langHelper";
import { useAppContext } from "../../context/AppContext";

const { Content } = Layout;

const vendor = "Vendor";

const Register = () => {
  const { setIsLoggedIn, setUserProfile } = useContext(AuthContext);
  const { language } = useAppContext();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const api = {
    signUp: apiPath.signUp,
    sendOTP: apiPath.sendOtp,
    verifyOTP: apiPath.verifyOTP,
    country: apiPath.common.country,
    city: apiPath.common.city,
    categories: apiPath.common.categories,
    subCategory: apiPath.common.subCategories,
  };

  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState();
  const [cities, setCities] = useState([]);

  const [mobileNumber, setMobileNumber] = useState(null);

  const [registrationDetails, setRegistrationDetails] = useState({
    mobile_number: undefined,
    country_code: undefined,
    email: undefined,
  });
  const [showOtpScreen, setShowOtpScreen] = useState(false);

  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);

  const { request } = useRequest();

  const getCountry = () => {
    request({
      url: api.country,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "Country");
        if (data) {
          setCountries(data.data || []);
        }
      },
    });
  };

  const getCities = (id) => {
    request({
      url: `${api.city}/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "setCities");
        if (data) {
          setCities(data.data || []);
        }
      },
    });
  };

  const getCategory = () => {
    request({
      url: api.categories,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setCategory(data.data || []);
        }
        console.log(data, "data");
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const getSubCategory = (id) => {
    console.log("evalue", id);

    request({
      url: `${api.subCategory}/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setSubCategory(data.data || []);
        }
        console.log(data, "data");
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const onFinish = (values) => {
    let payload = {
      ...values,
      ...mobileNumber,
    };

    setRegistrationDetails({ ...values, ...mobileNumber });

    request({
      url: api.signUp,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        console.log(data, "fghdjh data");
        console.log("data.status", data.status);
        if (data.status) {
          console.log(data, "data.....");
          ShowToast(data.message, Severty.SUCCESS);
          setShowOtpScreen(true);
          
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const handleMobileNumberChange = (value, data, type) => {
    let country_code = data?.dialCode;
    setMobileNumber({
      country_code: country_code,
      mobile_number: value.slice(data?.dialCode?.length),
    });
  };

  const handleCountryChange = (e) => {
    form.setFieldsValue({
      area: undefined,
      city_id: undefined,
      category_id: undefined,
    });
    getCities(e);
  };

  useEffect(() => {
    getCountry();
    getCategory();
  }, []);



  return (
    <>
      {showOtpScreen ? (
        <Otp
          data={registrationDetails}
          loading={loading}
        />
      ) : (
        <div className="sign-up-bg">
          <Row> 
            <Col span={24} md={18} lg={16} xl={14}  className="mx-auto">
                <div className="regi-back">
                  <div className="header-control imglogo-txt px-0"  >
                      <img src={signinLogo} />
                      <div className="headding-regi">
                       <h3> {lang("Grow your business online with Planit")}</h3>
                        <div className="dics-text">
                            <p> {lang(
                            "Partner with us to reach more customers, earn more money and grow your business online - your success story begins here."
                          )}</p>
                          <div className="header-control  px-0" style={{ textAlign: "right" }} >
                                <Select
                                  value={language}
                                  options={[
                                    {
                                      label: "English",
                                      value: "en",
                                    },
                                    {
                                      label: "عربي",
                                      value: "ar",
                                    },
                                  ]}
                                  onChange={(value) => {
                                    localStorage.setItem("languageSet", value);
                                    window.location.reload();
                                  }}
                                />
                              </div>
                        </div>
                      </div>
                    </div>
                   
                  <Form
                    initialValues={{}}
                    id="create"
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                    className="row-col"
                  >

                    <Row className="mx-auto">
                      <Col span={24} >
                      
                        <Card>
                          <Row gutter={20}>
                            <TextInputBox
                              name="name"
                              label={lang("Business Name")}
                              placeholder={lang("Enter Business Name")}
                              rules={[
                                {
                                  max: 250,
                                  message: lang(
                                    "Name should contain more then 250 characters!"
                                  ),
                                },
                                {
                                  required: true,
                                  message: lang("Please Enter Business Name"),
                                },
                              ]}
                            />
                            <TextInputBox
                              name="description"
                              label={lang("Business Description")}
                              placeholder={lang("Enter Business Description")}
                              rules={[
                                {
                                  max: 500,
                                  message: lang(
                                    "Description should contain more then 500 characters!"
                                  ),
                                },
                                {
                                  required: true,
                                  message: lang(
                                    "Please Enter Business Description"
                                  ),
                                },
                              ]}
                            />
                          </Row>
                          <Row gutter={20}>
                            <SelectInput
                              name="country_id"
                              label={lang("Country")}
                              placeholder={lang("Select country")}
                              options={countries?.map((item) => ({
                                ...item,
                                name: item?.name,
                              }))}
                              handleChange={(e, key) => {
                                setCountry(e);
                                handleCountryChange(e);
                              }}
                              cover={{ md: 12 }}
                              colProps={{ sm: 24, span: 24 }}
                              showSearch
                              filterOption={(input, option) =>
                                option?.label
                                  ?.toLowerCase()
                                  ?.indexOf(input.toLowerCase()) >= 0
                              }
                              rules={[
                                {
                                  required: true,
                                  message: lang("Missing Type Selection"),
                                },
                              ]}
                            />

                            <SelectInput
                              name="city_id"
                              label={lang("City")}
                              placeholder={lang("Select City")}
                              options={cities?.map((item) => ({
                                ...item,
                                name: item?.name,
                              }))}
                              cover={{ md: 12 }}
                              colProps={{ sm: 24, span: 24 }}
                              showSearch
                              filterOption={(input, option) => {
                                console.log(input, option, "option");
                                return (
                                  option?.label
                                    ?.toLowerCase()
                                    ?.indexOf(input.toLowerCase()) >= 0
                                );
                              }}
                              rules={[
                                {
                                  required: true,
                                  message: lang("Missing Type Selection"),
                                },
                              ]}
                            />
                          </Row>
                        </Card>
                        <Card>
                          <Row gutter={20}>
                            <SelectInput
                              name="category_id"
                              label={lang("Category Name")}
                              placeholder={lang("Select Category")}
                              options={category?.map((item) => ({
                                ...item,
                                name: language !== "en" && language !== null
                                ? item?.[`${language}_name`] ?? item?.name
                                : item?.name,
                              }))}
                              cover={{ md: 12 }}
                              colProps={{ sm: 24, span: 24 }}
                              showSearch
                              filterOption={(input, option) => {
                                return (
                                  option?.label
                                    ?.toLowerCase()
                                    ?.indexOf(input.toLowerCase()) >= 0
                                );
                              }}
                              rules={[
                                {
                                  required: true,
                                  message: lang("Missing Type Selection"),
                                },
                              ]}
                              handleChange={(e) => {
                                getSubCategory(e);
                                form.setFieldsValue({ sub_category_id: [] });
                              }}
                            />
                            <SelectInput
                              mode="multiple"
                              name="sub_category_id"
                              label={lang("Sub Category Name")}
                              placeholder={lang("Select Sub Category")}
                              options={subCategory?.map((item) => ({
                                ...item,
                                name: item?.name,
                              }))}
                              cover={{ md: 12 }}
                              colProps={{ sm: 24, span: 24 }}
                              showSearch
                              filterOption={(input, option) => {
                                console.log(input, option, "option");
                                return (
                                  option?.label
                                    ?.toLowerCase()
                                    ?.indexOf(input.toLowerCase()) >= 0
                                );
                              }}
                              rules={[
                                {
                                  required: subCategory?.length ?  true : false,
                                  message: lang("Missing Type Selection"),
                                },
                              ]}
                            />
                          </Row>
                        </Card>

                        <Row gutter={20}>
                          <Col span={24} md={12}>
                            <Card>
                              <Row>
                                <TextInputBox
                                  name="contact_person_name"
                                  label={lang("Contact Person Name")}
                                  placeholder={lang("Enter Contact Person Name")}
                                  cover={{ md: 24 }}
                                  colProps={{ sm: 24, span: 24 }}
                                  rules={[
                                    {
                                      max: 250,
                                      message: lang(
                                        "Name should contain more then 250 characters!"
                                      ),
                                    },
                                    {
                                      required: true,
                                      message: lang("Please Enter Name"),
                                    },
                                  ]}
                                />
                              </Row>
                              <Row>
                                <Col span={24} md={24}>
                                  <Form.Item
                                    label={lang("Email ID")}
                                    name="email"
                                    rules={[
                                      {
                                        type: "email",
                                        message: lang(
                                          "Please enter a valid email address!"
                                        ),
                                      },
                                      {
                                        max: 255,
                                        message: lang(
                                          "Email address not more then 255 characters!"
                                        ),
                                      },
                                      {
                                        required: true,
                                        message: lang("Please enter email!"),
                                      },
                                    ]}
                                  >
                                    <Input placeholder={lang("Enter Email ID")} />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row>
                                <PhoneNumberField
                                  label={lang("Contact Person Phone Number")}
                                  name="mobile"
                                  placeholder={lang("Enter Phone Number")}
                                  cover={{ md: 24 }}
                                  colProps={{ sm: 24, span: 24 }}
                                  className=""
                                 number={mobileNumber?.mobile_number}
                                  onChange={handleMobileNumberChange}
                                />
                              </Row>
                            </Card>
                          </Col>
                          <Col span={24} md={12}>
                            <Card>
                              <Row>
                                <Col span={24} md={24}>
                                  <Form.Item
                                    label={lang("Create Password")}
                                    name="password"
                                    rules={[
                                      {
                                        pattern: new RegExp(
                                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/
                                        ),
                                        message: lang(
                                          "Confirm password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character"
                                        ),
                                      },
                                      {
                                        required: true,
                                        message: lang(
                                          "Please enter your password!"
                                        ),
                                      },
                                    ]}
                                  >
                                    <Input.Password
                                      onCut={(e) => e.preventDefault()}
                                      onCopy={(e) => e.preventDefault()}
                                      onPaste={(e) => e.preventDefault()}
                                      autoComplete="off"
                                      placeholder={lang("Create Password")}
                                    />
                                  </Form.Item>
                                </Col>

                                <Col span={24} md={24}>
                                  <Form.Item
                                    label={lang("Confirm Password")}
                                    name="confirm_password"
                                    dependencies={["password"]}
                                    hasFeedback
                                    rules={[
                                      {
                                        required: true,
                                        message: lang(
                                          "Enter the confirm password!"
                                        ),
                                      },

                                      ({ getFieldValue }) => ({
                                        validator(_, value) {
                                          if (
                                            !value ||
                                            getFieldValue("password") === value
                                          ) {
                                            return Promise.resolve();
                                          }
                                          return Promise.reject(
                                            new Error(
                                              lang(
                                                "Password that you entered doesn't match!"
                                              )
                                            )
                                          );
                                        },
                                      }),
                                    ]}
                                  >
                                    <Input.Password
                                      placeholder={lang("Enter Confirm Password")}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        </Row>

                        <Row gutter={20}>
                          <Col md={24}>
                            <Form.Item
                              name="terms_and_conditions"
                              valuePropName="checked"
                              rules={[
                                {
                                  validator: (_, value) => {
                                    if (value !== undefined && value === true) {
                                      return Promise.resolve();
                                    }
                                    return Promise.reject(
                                      new Error(
                                        lang(
                                          "Please accept the terms and conditions"
                                        )
                                      )
                                    );
                                  },
                                },
                              ]}
                            >
                              <Space align="baseline">
                                <Checkbox
                                //defaultChecked={true}
                                >
                                  {lang("I accept the")}{" "}
                                  <Link to="/terms-and-conditions" target="_blank">
                                    {lang("Contract Terms and Conditions")}
                                  </Link>
                                </Checkbox>
                              </Space>
                            </Form.Item>
                          </Col>
                        </Row>
                        <div className="footer-register">
                         
                          <Button
                            className="primary_btn btnStyle " 
                          >
                              <Link to={"/login"}> {lang("Back")}</Link>
                          </Button>
                          <Button
                            className="primary_btn btnStyle "
                            htmlType="submit"
                          >
                            {lang("SUBMIT")}
                          </Button>
                        </div>
                       
                      </Col>
                    </Row>
                  </Form>
                  </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default Register;
