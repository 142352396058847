import { message, Modal, Upload as UploadAntd } from "antd";
import React, { useState, useEffect } from "react";
import { PlusOutlined } from '@ant-design/icons';
import { uploadFile } from 'react-s3';
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';
import lang from "../helper/langHelper";

const s3Config = {
  region: 'us-east-1',
  credentials: {
    accessKeyId: "S44KODSY6ZGTPE2GS87K",
    secretAccessKey: "8tt6C3T7g2es32AhOSBzCYJfAsiS2NUCXkiqMLxG",
  },
  endpoint: "https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/",
  forcePathStyle: true,
};


const s3Client = new S3Client(s3Config);

const MultipleImageUpload = ({ data, fileType, imageType, btnName, onDelete, onChange, maxCount = 8 }) => {

  const [fileList, setFileList] = useState([]);

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    setPreviewImage(file.url || file.thumbUrl);
    setPreviewVisible(true);
  };



  const beforeUpload = (file) => {
    if (fileType.includes(file.type)) {

    } else {
      message.error("File format is not correct")
      return false
    }
    const isLt2M = file.size / 1024 / 1024 < 5;

    if (!isLt2M) {
      message.error(`Image must be smaller than 5 MB!`)
      return false
    }
    return true
  }

  useEffect(() => {
    if (!data?.length) return;
    const multipleFileList = data.map((url, index) => ({
      uid: `${index + 1}`,
      name: url,
      status: 'done',
      url: url,
      thumbUrl: url,
    }));
    setFileList(multipleFileList)
  }, [data])






  const uploadFileToS3 = async (file, bucketName) => {
    const key = "plaint/" + imageType + "/" + file.name
    const params = {
      Bucket: bucketName,
      Key: key,
      Body: file,
      ContentType: file.type,
    };

    const upload = new Upload({
      client: s3Client,
      params: params,
    });

    try {
      const data = await upload.done();
      console.log(data)
      return data;
    } catch (err) {
      throw err;
    }
  };

  const handleImgChange = async (event) => {

    const { file } = event;

    try {
      const data = await uploadFileToS3(file, 'invent-colab-obj-bucket');
      const fileData = {
        uid: file.uid,
        name: file.name,
        status: 'done',
        url: data.Location,
        thumbUrl: data.Location,
      };
      setFileList([...fileList, fileData]);
      if (onChange) {
        onChange([...fileList, fileData]);
      }


      //   setFile([fileData]);
      console.log(data);
    } catch (err) {
      console.error(err);
    }
  };


  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const uploadButton = (
    <div> <PlusOutlined /> <div style={{ marginTop: 8 }}>{lang("Upload")} {btnName}</div> </div>
  );

  const handleRemove = (file) => {
    console.log(fileList, file);
    const newFile = fileList.filter(item => item.uid != file.uid)
    setFileList(newFile)
    if (onChange) {
      onChange([...newFile]);
    }
    // onDelete(file.url)
  };

  return (
    <>
      <UploadAntd
        listType="picture-card"
        onRemove={handleRemove}
        onPreview={handlePreview}
        maxCount={maxCount}
        beforeUpload={beforeUpload}
        fileList={fileList}
        //onChange={handleChange}
        customRequest={handleImgChange}
      >
        {fileList.length >= maxCount ? null : uploadButton}
      </UploadAntd>
      <Modal
        bodyStyle={{ padding: "0px !important", width: "auto !important", background: "black" }}
        // style={{ padding: "0px !important", background: "black" }}
        open={previewVisible}
        title={''}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ minWidth: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

export default MultipleImageUpload;