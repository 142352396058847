import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Modal,
  Form,
  Tag,
  Image,
  Tooltip,
  Select,
  DatePicker,
  Input,
  Rate,
} from "antd";
import apiPath from "../../constants/apiPath";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import ShowTotal from "../../components/ShowTotal";
import ConfirmationBox from "../../components/ConfirmationBox";
import SingleImageUpload from "../../components/SingleImageUpload";
import notfound from "../../assets/images/not_found.png";
import EditIcon from "../../assets/images/edit.svg";
import { bannerType } from "../../constants/conts";
import moment from "moment";
import lang from "../../helper/langHelper";

const { RangePicker } = DatePicker;

function Ratings({data ,loading}) {
  const sectionName = "Banner";
  const routeName = "banner";

  const [list, setList] = useState(data);
  const [pagination, setPagination] = useState(5);


  useEffect(()=>{
    if(!data) return;
    setList(data)
    setPagination(data?.length)
    console.log("ratingdata ::", data)
  },[data])

  const columns = [
    {
      title: lang("Reviewer Image"),
      dataIndex: "image",
      key: "image",
      render: (_, { reviewer_id }) => (
        <Image
          width={50}
          src={reviewer_id?.image ? reviewer_id?.image : notfound}
          className="table-img"
        />
      ),
    },
 

    {
      title: lang("Reviewer Name"),
      dataIndex: "reviewer_id",
      key: "reviewer_id",
      render: (_, { reviewer_id }) => {
        return reviewer_id?.name ? <span className="cap">{reviewer_id?.name}</span> : "-";
      },
    },
    {
      title: lang("Rating"),
      dataIndex: "rating",
      key: "rating",
      render: (_, { rating }) => {
        return rating ? <span className="cap"> <Rate disabled value={1} count={1}/>  { rating }</span> : "0";
        
      }
    },
    {
      title: lang("Rated On"),
      dataIndex: "created_at",
      key: "created_at",
      render: (_, { created_at }) => {
        return moment(created_at).format("DD-MM-YYYY");
      },
    },

  ];


  return (
    <>
      <div className="tabled bannerMain">
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card bordered={false} className="criclebox tablespace mb-24">
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{
                    defaultPageSize: 5,
                    responsive: true,
                    total: pagination,
                    // showSizeChanger: true,
                    // pageSizeOptions: ["10", "20", "30", "50"],
                  }}
                  // onChange={handleChange}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
     
    </>
  );
}



export default Ratings;
