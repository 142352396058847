import { Col, Row, Skeleton } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UndoOutlined } from "@ant-design/icons";
import notfound from "../../assets/images/not_found.png";

import SectionWrapper from "../../components/SectionWrapper";
import apiPath from "../../constants/apiPath";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import { Accordion } from "react-bootstrap";
import { AppStateContext, useAppContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import deleteWhiteIcon from "../../assets/images/icon/deleteWhiteIcon.png";
import Loader from "../../components/Loader";
import { Container } from "react-bootstrap";

function Index() {
  const heading = lang("Content") + " " + lang("Management");
  const { setPageHeading, country } = useContext(AppStateContext);

  const { request } = useRequest();

  const [list, setList] = useState([]);
  const [privacy, setPrivacy] = useState([]);
  const [abouts, setAbouts] = useState([]);
  const [terms, setTerms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const {language} = useAppContext()
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getPrivacy();
    getTerms();
    getAbout();
  }, [refresh]);

  useEffect(() => {
    setPageHeading(heading);
  }, [setPageHeading]);

  const getPrivacy = () => {
    request({
      url: `${apiPath.common.content}/privacy-policy`,
      method: "GET",
      onSuccess: ({ data }) => {
        setLoading(false);
        setPrivacy(data.data);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const getAbout = () => {
    request({
      url: `${apiPath.common.content}/about-us`,
      method: "GET",
      onSuccess: ({ data }) => {
        setLoading(false);
        setAbouts(data.data);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const getTerms = () => {
    request({
      url: `${apiPath.common.content}/service-provider-agreement`,
      method: "GET",
      onSuccess: ({ data }) => {
        setLoading(false);
        setTerms(data.data);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  return (
    <>
      <SectionWrapper cardHeading={lang(`About Us`)}>
        {loading ? (
          [1, 2, 3, 4, 5, 6, 12, 8, 9].map((item) => (
            <Skeleton active key={item} />
          ))
        ) : (
          <>
            <Row gutter={[24, 24]} className="main-abuutus-usmain">
              <Col span={24} xl={24}>
                {/* <b>{lang("English")}</b> */}
                <div
                  className="terms-left-outer"
                  dangerouslySetInnerHTML={{
                    __html: abouts ?  language !== "en" && language !== null
                    ? abouts?.[`${language}_description`] ?? abouts?.description
                    : abouts?.description  : "",
                  }}
                />
              </Col>
              {/* <Col span={24} xl={12}>
                <b>{lang("Arabic")}</b>

                <div
                  className="terms-right-outer"
                  dangerouslySetInnerHTML={{
                    __html: abouts ? abouts.ar_description : "",
                  }}
                />
              </Col> */}
            </Row>
          </>
        )}
      </SectionWrapper>

      <SectionWrapper cardHeading={lang(`Terms and  Conditions`)}>
        {loading ? (
          [1, 2, 3, 4, 5, 6, 12, 8, 9].map((item) => (
            <Skeleton active key={item} />
          ))
        ) : (
          <>
            <Row gutter={24} className="main-abuutus-usmain">
              <Col span={24} xl={24}>
                {/* <b>English</b> */}
                <div
                  className="terms-left-outer"
                  dangerouslySetInnerHTML={{
                    __html: terms ?   language !== "en" && language !== null
                    ? terms?.[`${language}_description`] ?? terms?.description
                    : terms?.description : "",
                  }}
                />
              </Col>
              {/* <Col span={24} xl={12}>
                <b>Arabic</b>
                <div
                  className="terms-right-outer"
                  dangerouslySetInnerHTML={{
                    __html: terms ? terms.ar_description : "",
                  }}
                />
              </Col> */}
            </Row>
          </>
        )}
      </SectionWrapper>
      <SectionWrapper cardHeading={lang(`Privacy Policy`)}>
        {loading ? (
          [1, 2, 3, 4, 5, 6, 12, 8, 9].map((item) => (
            <Skeleton active key={item} />
          ))
        ) : (
          <>
            <Row gutter={[24, 36]} className="main-abuutus-usmain">
              <Col span={24} xl={24}>
                {/* <b>English</b> */}
                <div
                  className="terms-left-outer"
                  dangerouslySetInnerHTML={{
                    __html: privacy ? language !== "en" && language !== null
                    ? privacy?.[`${language}_description`] ?? privacy?.description
                    : privacy?.description : "",
                  }}
                />
              </Col>
              {/* <Col span={24} xl={12}>
                <b>Arabic</b>
                <div
                  className="terms-right-outer"
                  dangerouslySetInnerHTML={{
                    __html: privacy ? privacy.ar_description : "",
                  }}
                />
              </Col> */}
              <Col span={24}>
                {privacy?.faq?.map((item) => (
                  <Accordion className="freq-ask-neww-main">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="freq-ask-neww-998">
                        { language !== "en" && language !== null
                            ? item?.[`${language}_question`] ?? item?.question
                            : item?.question }
                      </Accordion.Header>
                      <Accordion.Body>
                        <p> { language !== "en" && language !== null
                            ? item?.[`${language}_answer`] ?? item?.answer
                            : item?.answer }</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                ))}
              </Col>
            </Row>
          </>
        )}
      </SectionWrapper>
    </>
  );
}

export default Index;
