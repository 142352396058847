// export const s3Config = (type) => ({
//   dirName: "planit/" + type,
//   bucketName: "sugamaya",
//   region: "us-east-1",
//   accessKeyId: "AKIA6GBMIAPJULCPWKU5",
//   secretAccessKey: "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",
// });


export const s3Config = (type) => ({
  dirName: "planit/" + type,
  bucketName: "planit-co-obj-bucket",
  region: "us-east-1",
  accessKeyId: "S44KODSY6ZGTPE2GS87K",
  secretAccessKey: "8tt6C3T7g2es32AhOSBzCYJfAsiS2NUCXkiqMLxG",
});