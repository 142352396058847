import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Switch,
  TimePicker,
  Space,
  Image,
  Collapse,
  Steps,
} from "antd";
import React, { useContext, useEffect, useState } from "react";

import {
  PhoneNumberField,
  SelectInput,
  TextInputBox,
} from "../../components/InputField";

import apiPath from "../../constants/apiPath";
import { AppStateContext, useAppContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import LocationMap from "../../components/LocationMap";

import moment from "moment";
import { DeleteOutlined } from "@ant-design/icons";

import EditIcon from "../../assets/images/edit.svg";
import Loader from "../../components/Loader";
import { AuthContext } from "../../context/AuthContext";

const weekdays = [
  { name: "sunday", label: lang("Sunday") },
  { name: "monday", label: lang("Monday") },
  { name: "tuesday", label: lang("Tuesday") },
  { name: "wednesday", label: lang("Wednesday") },
  { name: "thursday", label: lang("Thursday") },
  { name: "friday", label: lang("Friday") },
  { name: "saturday", label: lang("Saturday") },
];

const BussinessDetails = () => {
  const api = {
    addEdit: apiPath.updateProvider,
    fetch: apiPath.getProvider,
    country: apiPath.common.countries,
    city: apiPath.common.city,
    category: apiPath.common.categories,
    SubCategory: apiPath.common.subCategories,
    eventType: apiPath.common.eventType,
  };
  const sectionName = lang("Profile");
  const heading = sectionName + " " + lang("Management");
  const { setPageHeading } = useContext(AppStateContext);

  const [form] = Form.useForm();

  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [showInput, setShowInput] = useState([]);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [availableWorkingDays, setAvailableWorkingDays] = useState(weekdays);
  const [data, setData] = useState(null);
  const [location, setLocation] = useState(null);
  const [editLocation, setEditLocation] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [event, setEvent] = useState([]);
  const [cities, setCities] = useState([]);
  const {language} = useAppContext()
  const { userProfile , setRefreshUserProfile } = useContext(AuthContext);



  const format = "h:mm a";
  useEffect(() => {
    setPageHeading(heading);
  }, []);

  const getCategory = () => {
    request({
      url: api.category,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setCategory(data.data);
        }
        console.log(data, "data");
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const getSubCategory = (id) => {
    console.log("evalue", id);

    request({
      url: `${api.SubCategory}/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setSubCategory(data.data);
        }
        console.log(data, "data");
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const getEvent = () => {
    request({
      url: `${api.eventType}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          console.log(data);
          setEvent(data.data);
        }
        console.log(data, "data");
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const getCountry = () => {
    request({
      url: apiPath.common.country,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setCountries(data.data);
        }
      },
    });
  };

  const getCity = (id) => {
    request({
      url: apiPath.common.city + "/" + id,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setCities(data?.data);
        }
      },
    });
  };

  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];

  const getProvider = () => {
    setLoading(true);
    request({
      url: api.fetch,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setLoading(false);

        if (status) {
          setData(data?.data);
        }
        console.log(data, "lll");
      },
      onError: (err) => {
        setLoading(false);

        console.log(err);
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    getProvider();
    getCategory();
    getCountry();
  }, []);

  useEffect(() => {
    if (!data) return;

    console.log(data, "vendorEdit");
    form.setFieldsValue({
      ...data,
      mobile: data.country_code + data.mobile_number,
      country_id: data?.country_id,
      city_id: data?.city_id?._id,
      category_id: data?.category_id,
      sub_category_id: data?.sub_category_id,

      location_on_map: data.address,

      working_days: data.working_days.map((day) => ({
        day: day.day,
        time: [moment(day.open_time), moment(day.close_time)],
      })),
    });
    setShowInput(data?.profile_id?.permission);
    setLocation({
      map_address: data.map_address,
      latitude: data.latitude,
      longitude: data.longitude,
    });

    setEditLocation({
      map_address: data.map_address,
      latitude: data.latitude,
      longitude: data.longitude,
    });

    getCategory();
    getSubCategory(data?.category_id);
    getEvent(data?.category_id);
    getCity(data?.country_id);
    getCountry();
  }, [data]);

  const onCreate = (values) => {
    const { location, working_days } = values;

    let payload = {
      ...values,
      ...mobileNumber,
      ...location,
      category_id: values.category_id,
      sub_category_id: values.sub_category_id,
    };

    console.log(payload, "payload");

    request({
      url: api.addEdit,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          setIsEdit(false);
          getProvider();
          setRefreshUserProfile(prev => !prev)

        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  return (
    <>
      {" "}
      <React.Fragment>
        {data ? (
          <Col span={24} lg={24} xs={24}>
            <div className="row g-3">
              {/* <div className="col-12 col-sm-6">
                <h4 className="modal_title_cls mb-2 modal_title_menu text-start">
                  Business Details
                </h4>
              </div> */}
              <div className="col-12 d-flex align-items-center gap-3 justify-content-end">
                <Button
                  disabled={!data}
                  onClick={() => setIsEdit(true)}
                  className="edit-cls btnStyle primary_btn  py-2 px-3  d-flex align-items-center gap-1"
                >
                  <img src={EditIcon} />
                  {lang("Edit")}
                </Button>
              </div>
            </div>
          </Col>
        ) : (
          ""
        )}
        <Form
          id="create"
          form={form}
          onFinish={onCreate}
          layout="vertical"
          disabled={data ? !isEdit : false}
        >
          <Row gutter={[16, 16]}>
            <Col span={24} lg={24} xs={24} className="d-flex flex-column gap-3">
              <Card className="main-newheader-ofall" title={lang("Business Details")}>
                <Row gutter={24}>
                  <Col span={24} md={12}>
                    <Form.Item
                      label={lang("Category Name")}
                      name="category_id"
                      rules={[
                        {
                          required: true,
                          message: lang("Please select the category!"),
                        },
                      ]}
                    >
                      <Select
                        disabled={true}
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder={lang("Select Category")}
                        showSearch
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        onChange={(e, key) => {
                          getSubCategory(e);

                          setShowInput([]);
                          form.setFieldsValue({ sub_category_id: [] });
                        }}
                      >
                        {category.map((item) => (
                          <Select.Option
                            key={item._id}
                            label={item.name}
                            value={item._id}
                          >
                            { language !== "en" && language !== null
                            ? item?.[`${language}_name`] ?? item?.name
                            : item?.name }
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>

            {loading ? (
              <Loader />
            ) : (
              <>
                {" "}
                <Col
                  span={24}
                  lg={24}
                  xs={24}
                  className="d-flex flex-column gap-3"
                >
                  <Card className="">
                    <Row gutter={[12]}>
                      {showInput?.map((item) => (
                        <React.Fragment key={item?._id}>
                          {item.is_selected && item.name === "name" && (
                            <Col span={24} md={8}>
                              <TextInputBox
                                name={"name"}
                                label={item.label}
                                placeholder={lang("Enter " + item.label)}
                                cover={{ md: 24 }}
                                colProps={{ sm: 24, span: 24 }}
                                rules={[
                                  {
                                    max: 250,
                                    message: lang(
                                      "Name should contain more than 250 characters!"
                                    ),
                                  },
                                  {
                                    required: item.is_required,
                                    message: lang("Please Enter " + item.label),
                                  },
                                ]}
                              />
                            </Col>
                          )}
                          {item.is_selected && item.name === "ar_name" && (
                            <Col span={24} md={8}>
                              <TextInputBox
                                name={"ar_name"}
                                label={item.label}
                                placeholder={lang("Enter " + item.label)}
                                cover={{ md: 24 }}
                                colProps={{ sm: 24, span: 24 }}
                                rules={[
                                  {
                                    max: 250,
                                    message: lang(
                                      "Name should contain more than 250 characters!"
                                    ),
                                  },
                                  {
                                    required: item.is_required,
                                    message: lang("Please Enter " + item.label),
                                  },
                                ]}
                              />
                            </Col>
                          )}
                          {item.is_selected && item.name === "description" && (
                            <Col span={24} md={8}>
                              <TextInputBox
                                name="description"
                                label={lang(item.label)}
                                placeholder={lang("Enter Business Description")}
                                cover={{ md: 24 }}
                                colProps={{ sm: 24, span: 24 }}
                                rules={[
                                  {
                                    max: 500,
                                    message: lang(
                                      "Description should contain more then 500 characters!"
                                    ),
                                  },

                                  {
                                    required: item.is_required,
                                    message: lang("Please Enter " + item.label),
                                  },
                                ]}
                              />
                            </Col>
                          )}
                          {item.is_selected &&
                            item.name === "ar_description" && (
                              <Col span={24} md={8}>
                                <TextInputBox
                                  name="ar_description"
                                  label={lang("Business Description Arabic")}
                                  placeholder={lang(
                                    "Enter Business Description"
                                  )}
                                  cover={{ md: 24 }}
                                  colProps={{ sm: 24, span: 24 }}
                                  rules={[
                                    {
                                      max: 500,
                                      message: lang(
                                        "Description should contain more then 500 characters!"
                                      ),
                                    },
                                    {
                                      required: item.is_required,
                                      message: lang(
                                        "Please Enter " + item.label
                                      ),
                                    },
                                  ]}
                                />
                              </Col>
                            )}
                          {item.is_selected &&
                            item.name === "sub_category_id" && (
                              <Col span={24} md={8}>
                                <Form.Item
                                  label={lang(`Sub Category Name`)}
                                  name="sub_category_id"
                                  rules={[
                                    {
                                      required: subCategory?.length && item.is_required ? true : false,
                                      message: lang(
                                        "Please Enter " + item.label
                                      ),
                                    },
                                  ]}
                                >
                                  <Select
                                    filterOption={(input, option) =>
                                      option.label
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                    placeholder={lang("Select Sub Category")}
                                    showSearch
                                    mode="multiple"
                                  >
                                    {subCategory?.map((item) => (
                                      <Select.Option
                                        key={item._id}
                                        label={item.name}
                                        value={item._id}
                                      >
                                             { language !== "en" && language !== null
                                        ? item?.[`${language}_name`] ?? item?.name
                                        : item?.name }
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                            )}
                        </React.Fragment>
                      ))}
                    </Row>
                  </Card>
                </Col>
                <Col
                  span={24}
                  lg={24}
                  xs={24}
                  className="d-flex flex-column gap-3"
                >
                  <Card>
                    <div className="row g-3">
                      {showInput?.map((item) => (
                        <React.Fragment key={item._id}>
                          {item.is_selected && item.name === "website_url" && (
                            <>
                              <Col span={24} md={12}>
                                <TextInputBox
                                  name={"twitter_link"}
                                  label={"Twitter Link"}
                                  placeholder={lang("Enter Twitter Link")}
                                  cover={{ md: 24 }}
                                  colProps={{ sm: 24, span: 24 }}
                                  rules={[
                                    {
                                      required: item.is_required,
                                      message: lang(
                                        "Please enter twitter link"
                                      ),
                                    },
                                  ]}
                                />
                              </Col>

                              <Col span={24} md={12}>
                                <TextInputBox
                                  name={"facebook_link"}
                                  label={"FaceBook Link"}
                                  placeholder={lang("Enter FaceBook Link")}
                                  cover={{ md: 24 }}
                                  colProps={{ sm: 24, span: 24 }}
                                  rules={[
                                    {
                                      required: item.is_required,
                                      message: lang(
                                        "Please Enter facebook link"
                                      ),
                                    },
                                  ]}
                                />
                              </Col>
                              <Col span={24} md={12}>
                                <TextInputBox
                                  name={"instagram_link"}
                                  label={"Instagram Link"}
                                  placeholder={lang("Enter Instagram Link")}
                                  cover={{ md: 24 }}
                                  colProps={{ sm: 24, span: 24 }}
                                  rules={[
                                    {
                                      required: item.is_required,
                                      message: lang(
                                        "Please Enter Instagram link"
                                      ),
                                    },
                                  ]}
                                />
                              </Col>
                            </>
                          )}

                          {item.is_selected && item.name === "location" && (
                            <Col span={24} md={12}>
                              <Form.Item
                                label={lang(
                                  "Location (Drag Marker for Selecting Location)"
                                )}
                                name="location"
                                rules={[
                                  {
                                    required: item.is_required,
                                    message: lang("Please Enter " + item.label),
                                  },
                                ]}
                              >
                                <LocationMap
                                  className="mt-3"
                                  editLocation={editLocation}
                                />
                              </Form.Item>
                            </Col>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  </Card>
                 <Card>
                    <h4 className="profile-headding">{lang("Select Event")} </h4>
                    <div className="row g-3">
                      {showInput?.map((item) => (
                        <React.Fragment key={item._id}>
                          {item.is_selected && item.name === "eventtype_id" && (
                            <div className="col-md-12">
                              <div className="row g-3">
                                <Col span={24} md={24}>
                                  <Form.Item
                                    // label="Event"
                                    name="eventtype_id"
                                    rules={[
                                      {
                                        required: item.is_required,
                                        message: lang("Please Enter event type"),
                                      },
                                    ]}
                                  >
                                    <Select
                                      placeholder="Select Event"
                                      // onChange={(e) => {
                                      //   handleSelectWorkDays(e);
                                      // }}
                                      getPopupContainer={(triggerNode) =>
                                        triggerNode.parentNode
                                      }
                                      mode="multiple"
                                    >
                                      {event.map((item) => (
                                        <Select.Option
                                          value={item._id}
                                          key={item._id}
                                        >
                                            { language !== "en" && language !== null
                                            ? item?.[`${language}_name`] ?? item?.name
                                            : item?.name }
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  </Card>
               
                  <Card className="">
                    <h4 className="profile-heading">{lang("Working Day's")}</h4>
                    <div className="row g-3">
                      <div className="col-lg-12  px-0">
                        <Row gutter={24}>
                          {showInput?.map((item) => (
                            <React.Fragment key={item._id}>
                                {item.is_selected && item.name === "working_days" && (
                            <Col span={24} md={24}>
                              <Form.List
                                name="working_days"
                                className="mt-2 "
                                initialValue={[{}]}
                              >
                                {(fields1, { add, remove }, {  }) => (
                                  <>
                                    {fields1.map((field_fr_1, index) => {
                                          const selectedDays = form
                                          .getFieldValue("working_days")
                                          ?.map((item, idx) => (idx !== index ? item?.day : null))
                                          .filter(Boolean);
                                     return(
                                       <div key={field_fr_1.key}>
                                        <Space
                                          key={field_fr_1.key}
                                          align="baseline"
                                          className="gap-cls"
                                        >
                                          <div className="row gap-3 m-3"></div>
                                          <div className="row gap-3 m-3">
                                            <Col span={24} md={8}>
                                              <Form.Item
                                                label="Select Day"
                                                name={[field_fr_1.name, "day"]}
                                                rules={[
                                                  {
                                                    required: item.is_required,
                                                    message: lang(
                                                      "Please Enter " +
                                                        item.label
                                                    ),
                                                  },
                                                ]}
                                              >
                                                <Select
                                                  placeholder="Select Working Day"
                                                  onChange={(value) => {
                                                    // Manually set the selected day in the form
                                                    form.setFieldsValue({
                                                      working_days : form.getFieldValue("working_days").map((dayItem, idx) =>
                                                        idx === index ? { ...dayItem, day: value } : dayItem
                                                      ),
                                                    });
                                                  }}
                                                  // onChange={(e) => {
                                                  //   handleSelectWorkDays(e);
                                                  // }}
                                                  getPopupContainer={(
                                                    triggerNode
                                                  ) => triggerNode.parentNode}
                                                >
                                                  {availableWorkingDays.map(
                                                    (day) => (
                                                      <Select.Option
                                                        value={day.name}
                                                        key={day.name}
                                                        disabled={selectedDays?.includes(day?.name)}

                                                       
                                                      >
                                                        {day.label}
                                                      </Select.Option>
                                                    )
                                                  )}
                                                </Select>
                                              </Form.Item>
                                            </Col>
                                            <Col span={24} md={8}>
                                              <Form.Item
                                                className="qty-cls "
                                                style={{
                                                  minWidth: "180px",
                                                }}
                                                name={[field_fr_1.name, "time"]}
                                                label="Enter Time Range"
                                                rules={[
                                                  {
                                                    required: item.is_required,
                                                    message: lang(
                                                      "Please Enter Time Range "
                                                    ),
                                                  },
                                                ]}
                                              >
                                                <TimePicker.RangePicker
                                                  defaultValue={moment(
                                                    "12:08",
                                                    format
                                                  )}
                                                  format={format}
                                                />
                                              </Form.Item>
                                            </Col>

                                            {/* <Col span={24} md={2}>
                                              <div className="addDelete_option">
                                                {index_fr_1 > 0 ? (
                                                  <div className="minus-wrap">
                                                    <div
                                                      className="delete-circal"
                                                      onClick={() =>
                                                        remove(field_fr_1.name)
                                                      }
                                                      style={{
                                                        borderRadius: "50%",
                                                        color: "#000",
                                                      }}
                                                    >
                                                      <DeleteOutlined />
                                                    </div>
                                                  </div>
                                                ) : null}
                                              </div>
                                            </Col> */}
                                            <Col
                                              span={8}
                                              sm={5}
                                              lg={6}
                                              className="mt-4"
                                            >
                                              <div className="add-menu-items-delete">
                                                {index ===
                                                field_fr_1.length - 1 ? (
                                                  <Form.Item className="addon-items">
                                                    <Button
                                                      onClick={() => add()}
                                                      block
                                                      className="primary_btn btnStyle"
                                                    >
                                                      <i class="fas fa-plus me-1"></i>
                                                      {lang("Add")}
                                                    </Button>
                                                  </Form.Item>
                                                ) : (
                                                  <div className="minus-wrap">
                                                    <DeleteOutlined
                                                      className="delete-circal"
                                                      onClick={() =>
                                                        remove(field_fr_1.name)
                                                      }
                                                      style={{
                                                        borderRadius: "50%",
                                                      }}
                                                    />
                                                  </div>
                                                )}
                                              </div>
                                            </Col>
                                          </div>
                                        </Space>
                                      </div>)
                                         })}

                                    <Col md={6} span={24}>
                                      <Form.Item style={{ marginTop: "10px" }}>
                                        <Button
                                          onClick={() => add()}
                                          block
                                          className="primary_btn btnStyle add-item-btn"
                                        >
                                          <i className="fas fa-plus" />{" "}
                                          {"            "}
                                          <span style={{ paddingLeft: "10px" }}>
                                            {lang(" Add Another Day")}
                                          </span>
                                        </Button>
                                      </Form.Item>
                                    </Col>
                                  </>
                                )}
                              </Form.List>
                            </Col>
                          )}
                            </React.Fragment>
                          ))}
                        </Row>
                      </div>
                    </div>
                  </Card>
                </Col>
              </>
            )}
            {isEdit ? (
              <div className="view-provider">
                <Button form="create" type="primary" htmlType="submit">
                  {lang("Submit")}
                </Button>
              </div>
            ) : (
              ""
            )}
          </Row>
        </Form>
      </React.Fragment>
    </>
  );
};

export default BussinessDetails;
