import {
    Button,
    Col,
    Layout,
    Row,
    Typography,
    Skeleton,Card
} from "antd";
import React, { useEffect, useState } from "react";

import useRequest from "../../hooks/useRequest";
import { Container } from "react-bootstrap";
import apiPath from "../../constants/apiPath";
import lang from "../../helper/langHelper";
import { useAppContext } from "../../context/AppContext";

const { Title } = Typography;
const { Content } = Layout;

const Terms = () => {

    const { request } = useRequest()
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)
    const {language} = useAppContext()

    const getData = () => {
        request({
            url: apiPath.terms,
            method: "GET",
            onSuccess: (res) => {
                setLoading(false)
                const { data } = res
                if (data.length) {
                    setData(data[0])
                }

            },
        });
    };

    useEffect(() => {
        getData()
    }, []);

    const closeTab = () => {
        window.opener = null;
        window.open("", "_self");
        window.close();
    };

    return (
        <>
            <Layout className="layout-default layout-signin">
                <Content className="signin-terms">
                    <Container>
                        <Card className="regi-back">
                            <div className="headding-regi">
                                 <h3 className="wlt-terms">{lang("Contract Terms and Conditions")}</h3>
                            </div>
                       {loading ? [1,2,3,4,5,6,12,8,9].map(item=><Skeleton active key={item}/>) : 
                        <>
                            <Row gutter={32}>

                                <Col span={24} xl={24}>
                                    <div className="terms-left-outer" dangerouslySetInnerHTML={{ __html: data ? language !== "en" && language !== null
                                              ? data?.[`${language}_description`] ?? data?.description
                                              : data?.description : '' }} />
                                </Col>
                                {/* <Col span={24} xl={12}>
                                    <div className="terms-right-outer" dangerouslySetInnerHTML={{ __html: data ? data.ar_description : '' }} />
                                </Col> */}
                            </Row>
                            <Button className="primary_btn btnStyle" onClick={closeTab}>
                                {lang("okay")}
                            </Button>
                        </>
                        }
                        </Card>
                    </Container>
                </Content>

            </Layout>
        </>
    );
};

export default Terms;
