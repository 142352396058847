import { useState } from "react";
import { Form, Modal, Row, Col, Radio } from "antd";
import lang from "../helper/langHelper";
import { ExclamationCircleFilled, EyeOutlined } from "@ant-design/icons";

const DeleteAccountWarning = ({ show, hide, onOk, title, subtitle, reasons }) => {
  const [value, setValue] = useState(reasons?.length > 0 ? reasons[1] : "");

  return (
    <Modal
      width={700}
      open={show}
      onOk={() => {
        if (onOk) onOk(value);
        hide();
      }}
      okText={lang("Delete")}
      cancelText={lang("Back")}
      onCancel={hide}
      centered
      className="tab_modal deleteWarningModal"
    >
      <Form layout="vertical" className="p-2">
      <div className="warning-main-898989"><ExclamationCircleFilled /></div>
        <h4 className="modal_title_cls mb-2">{title}</h4>
        <h4 className="modal_sub_title_cls mb-2 ">{subtitle}</h4>
      </Form>
    </Modal>
  );
};

export default DeleteAccountWarning;
