import { useNavigate } from "react-router";
import useRequest from "../../hooks/useRequest";
import { Form, Input, Col, Row, Modal, Image, InputNumber } from "antd";
import { Severty, ShowToast } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import SingleImageUpload from "../../components/SingleImageUpload";
import lang from "../../helper/langHelper";
import notfound from "../../assets/images/not_found.png";
import { useState } from "react";

const ReplyQuote = ({ show, hide, loading, refresh, data }) => {
  const [file, setFile] = useState([]);
  const [image, setImage] = useState();

  const [form] = Form.useForm();

  const { request } = useRequest();
  const navigate = useNavigate();
  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/webp",
    "image/gif",
  ];

  const handleImage = (data) => {
    //setImage(data);
    data.length > 0 ? setFile(data[0].url) : setFile([]);
    data.length > 0 ? setImage(data[0].url) : setImage([]);
  };

  const onSubmit = (values) => {
    const payload = { ...values };
    payload.quote_id = data._id;
    payload.user_id = data.user_id?._id;

    if (image?.length > 0) {
      payload.images = image;
    }
    //  return console.log(payload,"payload") ;
    request({
      url: `${apiPath.listQuote}`,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          form.resetFields();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (err) => {
        ShowToast(err, Severty.ERROR);
        console.log(err);
      },
    });
  };

  return (
    <>
      <Modal
        open={show}
        width={550}
        okText={lang("Submit")}
        cancelText={lang("Cancel")}
        onCancel={hide}
        okButtonProps={{
          form: "create",
          htmlType: "submit",
          loading: loading,
          className: "btn-primary-ft",
        }}
        className="tab_modal deleteWarningModal modal01"
        cancelButtonProps={{
          className: "btn-primary-cancel",
          htmlType: "Back",
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form
          id="create"
          form={form}
          onFinish={onSubmit}
          layout="vertical"
          className="row-col"
        >
          <div className="add_user_title">
            <h4 className="modal_title_cls">{lang("Send Reply")}</h4>
          </div>
          <Row gutter={[16, 0]}>
            <Col xl={24} md={24} span={24}>
              <Form.Item
                className="username"
                name="message"
                rules={[
                  {
                    required: true,
                    message: lang("Please enter a Comment...!"),
                  },
                ]}
              >
                <Input.TextArea placeholder={lang("Enter Comment...")} />
              </Form.Item>
            </Col>

            <Col span={24} sm={24}>
              <Form.Item
                initialValue={0}
                label={lang("Price (JOD)")}
                name={["price"]}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      //  if (!value) {
                      //   return Promise.reject(new Error(lang("price is required"))
                      //   );
                      // }

                      if (value < 0) {
                        return Promise.reject(
                          new Error(lang("Price must be a positive number"))
                        );
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <InputNumber placeholder={lang("Enter Price")} />
              </Form.Item>

              <Form.Item
                className=""
                // rules={[
                //   {
                //     validator: (_, value) => {
                //       if (value !== undefined && value?.length > 0) {
                //         return Promise.resolve();
                //       }
                //       return Promise.reject(new Error(lang("Image is required")));
                //     },
                //   },
                // ]}
                // label={lang("Upload Image")}
                name="image"
              >
                <SingleImageUpload
                  value={image}
                  fileType={FileType}
                  imageType={"category"}
                  btnName={lang("Image")}
                  onChange={(data) => handleImage(data)}
                  isDimension={false}
                />
              </Form.Item>
              {/* <p className="img-size-details">
              **
              {lang(
                "Images should be 600x600 for best view in gallery image. You can select only (.gif, .png, .jpeg, .jpg) format files upto 1 MB file size",
              )}
              ..!!!
            </p> */}
              {
                <div className="mt-2 add-img-product">
                  {" "}
                  <Image width={120} src={image ?? notfound}></Image>{" "}
                </div>
              }
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ReplyQuote;
